$app-dashboard-top-nav-height: rem-calc(128);

.toolbar  {
  background-color: $white;
  height: $app-dashboard-top-nav-height;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 2;

  & > h1 {
    font-size: 1.375rem;
    font-weight: 700;
    margin: 0 2rem .5rem;
    padding-top: 2rem;
    text-transform: uppercase;
  }
  & > .sections {
    margin: 0 1rem;
  }

  ul {
    background-color: transparent;

    a {
      border-radius: .25rem;
      color: $primary-color;
      font-weight: 300;
      padding: 1rem;
    
      &:hover {
        background-color: $success-color;
        color: $white;
      }
    }
  }

  @include breakpoint(medium) {
    @include flex;
    @include flex-align($x: justify);

    & > .sections {
      margin-right: 1rem;
      margin-top: 2.5rem;
      width: auto;
    }
    & > h1 {
      margin-left: 2rem;
      padding-top: 3rem;
      text-align: left;
      width: auto;
    }

    ul {
      @include flex-align($x: right);
    }
  }
}
