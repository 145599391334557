footer {
    a {
        color: $primary-color;
        border-radius: 4px;
        display: inline-block;
        font-size: 1.5rem;
        font-weight: 700;
        padding: 1rem 22px;

        @include breakpoint(medium) {
            font-size: 2.625rem;
        }
        
        &:hover {
            background-color: $success-color;
            color: $white;
        }
    }
    .callout.primary {
        margin-bottom: 0;
    }
    .copyright p {
        color: $darkerer-gray;
        font-size: .875rem;
        margin: 2rem 0;
        padding: 0 22px;

        @include breakpoint(medium) {
            display: block;

            br {
                display: none;
            }
        }
    }

}
h2 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: .8rem;
}
section {
    padding-bottom: 6rem;

    @include breakpoint(medium) {
        padding-bottom: 8rem;
    }

    & > div {
        max-width: 800px;
        margin: auto;
        padding-left: 22px;
        padding-right: 22px;

        p {
            color: $body-text;
            font-size: 1.125rem;
            font-weight: 300;
        }
    }

    figure {
        margin-bottom: 2rem;
        margin-left: -10px;
        margin-right: -10px;

        @include breakpoint(medium) {
            margin-bottom: 4rem;
            margin-left: 0;
            margin-right: 0;
        }
    }

    .headshot {
        border-radius: 50%;
        height: 240px;
        width: 240px;
    }
    .skills {
        border-top: 1px solid $dark-gray;
        color: $body-text;
        margin: 2rem 0 0;
        padding-top: 1.5rem;

        li {
            background-repeat: no-repeat;
            list-style-type: none;
            display: inline-block;
            font-size: .875rem;
            height: 2rem;
            margin-right: 2rem;
            padding-left: 2rem;
            width: 100%;

            &.skill-front-end-development {
                background-image: url('/assets/img/icons/code-tags.svg');
            }
            &.skill-graphic-design {
                background-image: url('/assets/img/icons/palette.svg');
            }
            &.skill-user-experience-design {
                background-image: url('/assets/img/icons/compass.svg');
            }
            &.skill-design-systems {
                background-image: url('/assets/img/icons/flip-to-front.svg');
            }

            @include breakpoint(medium) {
                width: auto;
            }
        }
    }
}
.callout.primary {
    background-color: $white;
    padding: 6rem 2rem 8rem;
    margin-bottom: 0;

    blockquote {
        @extend h1;

        border: none;
        color: $secondary-color;
        font-size: 2.25rem;
        font-weight: 700;

        br {
            display: none;
        }
    }
    cite {
        color: $darkerer-gray;
        font-size: 1rem;
        font-weight: 300;
    }

    @include breakpoint(medium) {
        margin-bottom: 8rem;
        padding-bottom: 16rem;
        padding-top: 16rem;
        
        blockquote,
        cite {
            margin-left: auto;
            margin-right: auto;
            max-width: 800px;
        }
        blockquote {
            font-size: 2.625rem;

            br {
                display: block;
            }
        }
    }
}